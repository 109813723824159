@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.datepicker-calendar-list-item {
	.react-calendar__month-view__weekdays {
		display: none !important;
	}

	.datepicker-calendar-month {
		&__month {
			@extend %text-bold;
			font-size: 1.5rem;
			margin-bottom: 17px;
			text-align: center;
			text-transform: capitalize;
		}
	}
}
